import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Cancer.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Cancer Health
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/cancer"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Cancer</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>             
              <h4 className="text-sm"></h4>

              <h4 className="text-sm">Cancer Health</h4>
              <h4 className="mx-2 text-sm"></h4>             
              <h4 className="text-sm"></h4>


            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Cancer Health </h1>
                <h2 className="text-sm md:text-base ml-4">Jun 22 - Jul 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-love");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-nature");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-man");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-traits");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-facts");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Cancer Health</p><br/>
           Your sign controls the elbows, chest, breasts, stomach and digestion, womb, and also female reproductive organs. You are apt to health concerns and mental anxiety due to complicated emotional makeup. You are tolerant of health concerns due to stress, anxiety, pressure, and emotional stress. <br/>
           <br></br>
           You usually suffer from poor health when you are emotionally or mentally, off and not tuned with your mind and heart. You manage to have a light and weak abdomen and digestive system. You own high intuition power and feel the relationship between mind and body. You are concerned with fitness exercises, activities, exercising, and workout as you feel they are handling to keep yourself calm and relaxed. You find personal space for yourself in your hectic timetable for adjusting your body. <br/>
           <br></br>
           Health issues won’t go away if you neglect them and they will get more threatening with the intake of rich food. You also encounter problems due to less water consumption. You maintain the amazing ability to feel sick without any fitness issues or feel too well when ailing or confronting any kind of issues. Worrying about your health in 2024? when we are here, you don't need to worry about anything. Talk to a genuine astrologer to get rid of all your problems.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Possible health concerns</p><br/>
           health-wise, you may suffer from varicose veins, dropsy, piles, pleurisy, and breast cancer. You are possible to have a weak digestive system and hence that's the reason behind you may suffer from gastric issues and other abdomen ailments. There are possibilities of coughs and weakness of eyesight. You may be inclined to gastric pains, ulcers, gall bladder upsets, and nausea.<br/>
           <br></br>
           You are possible to suffer from curvature of the spine, receding gums, and eye problems. You hold back emotions and feelings due to which you may suffer from abnormal weight gain and water retention issues. You are prone to skin diseases mainly due to a lack of calcium. You may suffer from a rollercoaster tummy or major abdominal cramps and heartburns. Do you need help in taking protection to stay fit? 
           Check Cancer Health Fitness Horoscope 2024<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Food for Cancer</p><br/>
           You should intake a mount of calcium fluoride in your daily- diet which combines with albumen and oil in the body's system to keep stretchy and connective tissues nutritious and healthy. The lack of it will lead to many problems like receding gums, varicose veins, a curvature of the spine, and eye problems. You should intake a potassium-rich diet as it will provide the aid you in dealing with stress and remains your body hydrated.<br/>
           <br></br>
           You should intake yogurt, beets, egg yolks, whole grain rye, fish, and oysters. Calcium-rich foods like milk, cheese, lettuce, and tomatoes will be in your diet. Okra is also a suitable option as it allows you in easing stomach inflammations. Your diet is a mix of fresh and organic vegetables, fruits, and lean protein.<br/>
           <br></br>
           You should bypass starch and sugar as it induces constipation. You should avoid salt as it completes bloating. You should also avoid spicy, highly flavored foodstuffs, and should forbear hot pepper dressing. You should keep a watch on the intake of sweets as extra intake makes your stomach worse. You love nutrition and hence should keep an eye on diet as well for a wholesome digestive system and to maintain your weight. The intake of fruit liquids and milkshakes will definitely help you to handle your mood swings and calm your crankiness.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Cancer Physical Appearance</p><br/>
           You have an average height, a fleshy body, and short legs. You have a rounded face, smooth skin, fair complexions, and noticeable foreheads. Your hair is usually brown and your eyes are small, rounded, and black, grey, and blue. Your nose is likely to be short, maybe upturned, and has a wide and smiling mouth. You have a fairly big skull, overhanging brows, and elevated cheekbones. You have enunciated lower jaw and prominent or uneven teeth. Your arms and legs may be proportionately lengthier than the rest of the body. Sometimes, You walk clumsily and shuffle slightly when you walk fast. Your shoulders are all-around and your hands and feet are relatively large. Not only your physique but also your overall personality makes you dynamic and unique! Know all about your nature or traits by accessing your Free Janampatri only here at BODHI!!<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Cancer Beauty</p><br/>
           Cancer natives own marvelous charm. You mostly attire according to your attitude or your mood. You love to prefer to wear blue or grey when unhappy, red when optimistic, and orange when promising. You will always be seen neatly outfitted, though it may not be in the most luxurious attire. Good and extra clothes are your trademark.<br/>
           <br></br>
           You cherish getting beauty products and remedies at an affordable price. Hence, usually, you are not devoted to any brand and revise beauty products and therapies quite frequently. You should prefer modest colors to blush. The light pink hue is a suitable option to make your cheeks glance a bit rosy. Silver is your underside color and shimmering rainbow colors fit you the best. Silver and rose shade nail polish gives amazing looks to your nails. You can add a glowing impression and luminous look to your face by applying foundation, highlighter, and shimmer to your upper cheekbones. Inherently toned eyeshadows like browns, tans, cream color, and mauve will pull off your look and beauty.<br/>
           <br></br>
           Brown and black eyeliners glimpse fab on your lovely and soft eyes. You love body-hugging costumes mostly silk and velvet fabrics. You carry off clothes with a vintage savor better than most. You choose to wear a pearl necklace as it brings out the emotional side of your nature.<br/>
           <br></br>
           Read more about Cancer
           <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
